.description {
  font-size: smaller;
}

.questionNumber {
  font-weight: bold;
}

.section {
  text-transform: uppercase;
  font-size: larger;
  font-size: 20px;
  text-align: center;
  color: #0045c8;
  font-weight: bold;
}

.invalidValue {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
