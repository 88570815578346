.rootContainer {
  background-color: #ffffff;
}

.headerContainer {
  background-color: #ffffff;
  font-size: 24px;
}

.title {
  color: #0045c8;
  font-size: 20px;
}

.subtitle {
  color: #0045c8;
  font-size: 12px;
}
