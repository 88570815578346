.dropdown button::after {
  display: none !important;
}

.title {
  font-weight: 400;
  font-size: 20px;
  color: #0045c8;
}

.subtitle {
  color: #adadad;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
}
