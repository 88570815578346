.container {
  max-width: 700px;
  border-style: solid;
  border-width: 3px;
  border-color: #d8d8d8;
  cursor: pointer;
}

.container:hover {
  border-color: #001e64;
  border-width: 3px;
}

.containerWithFile {
  max-width: 700px;
  border-style: solid;
  border-width: 3px;
  border-color: #d8d8d8;
}
