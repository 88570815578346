.container {
  max-width: 700px;
  border-style: solid;
  border-width: 3px;
  border-color: #d8d8d8;
  cursor: pointer;
}

.container:hover {
  border-color: #001e64;
  border-width: 3px;
}

.containerWithImage {
  max-width: 700px;
  border-style: solid;
  border-width: 3px;
  border-color: #d8d8d8;
}

.containerWithImage img {
  max-width: 100%;
}

.image {
  object-fit: scale-down;
}
